// @flow
import React from 'react'
import classNames from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
import Tooltip from '../../../../components/basic/Tooltip'
import baseStyles from './ContactLinks.scss'

type Props = {
  ...StyleProps,
  plainView?: boolean
}

const data = {
  phoneEN: '+49 211 933 01 10',
  phoneDE: '0211 933 01 10',
  mail: 'start@freshcells.de'
}

const sanitizePhone = (phone: string) => {
  return phone.replace(/\s+|-+|\(+|\)+/gim, '')
}

const ContactLinks = ({ styles, plainView }: Props) => {
  const { locale } = useIntl()
  const phone = locale === 'de' ? data.phoneDE : data.phoneEN
  const mail = data.mail
  const sanitizedPhone = sanitizePhone(phone)

  return (
    <div className={styles.root}>
      {plainView ? (
        <div className={styles.contentPlain}>
          <a href={'tel:' + sanitizedPhone} className={styles.linkPlain} target="_blank" rel="noopener noreferrer">
            <span className={classNames(styles.linkPlainIcon, 'icon-phone')} />
            <span className={styles.linkPlainText}>{phone}</span>
          </a>
          <a href={'mailto:' + mail} className={styles.linkPlain} target="_blank" rel="noopener noreferrer">
            <span className={classNames(styles.linkPlainIcon, 'icon-email')} />
            <span className={styles.linkPlainText}>{mail}</span>
          </a>
        </div>
      ) : (
        <div className={styles.content}>
          <Tooltip content={phone} placement="bottom" styles={styles.tooltip}>
            <a href={'tel:' + sanitizedPhone} className={styles.link} target="_blank" rel="noopener noreferrer">
              <span className={classNames(styles.linkIcon, 'icon-phone')} />
            </a>
          </Tooltip>
          <Tooltip content={mail} placement="bottom" styles={styles.tooltip}>
            <a href={'mailto:' + mail} className={styles.link} target="_blank" rel="noopener noreferrer">
              <span className={classNames(styles.linkIcon, 'icon-email')} />
            </a>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ContactLinks)
