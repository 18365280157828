import { defineMessages } from 'react-intl'

export default defineMessages({
  ITConsulting: 'Web Technology Consulting',
  UiUxDesign: 'UX Engineering & UI Design',
  SystemEngineering: 'Systems Engineering',
  ConceptAndStrategy: 'Software Concept & Strategy',
  Development: 'Web Application & Platform Development',
  AppDevelopment: 'App Development',
  ProjektManagement: 'Project Management',
  SystemIntegration: 'System Integration & Architecture',
  MightyC: 'mightyC®',
  Quickstart: 'Quickstart',
  Fusion: 'Fusion',
  Travelsandbox: 'TravelSandbox®',
  FreshMS: 'freshMS',
  Partner: 'Partner',
  WhyAgile: 'Why agile?',
  Technologies: 'Technologies',
  newsroom: 'Newsroom',
  WorkingAtFreshcells: 'Working at freshcells',
  Jobs: 'Jobs',
  GetInTouch: 'Get in touch',
  Copyright: '© Copyright {currentYear} by freshcells systems engineering GmbH',
  News: 'News',
  PressReleases: 'Press Releases',
  PrivacyPolicy: 'Privacy Policy',
  Imprint: 'Company Information',
  caseStudies: 'Case Studies',
  references: 'All References',
  titleReferences: 'References',
  titleServices: 'Services',
  titleSolutions: 'Solutions',
  titleProducts: 'Touristic Products',
  titleAboutUs: 'About Us',
  DERtouristik: 'DER Touristik',
  lufthansaHolidays: 'Lufthansa Holidays',
  plattenplaner: 'Plattenplaner',
  bedfinder: 'Bedfinder',
  etravel: 'etravel',
  aldiana: 'Aldiana',
  weekend: 'Weekend.com',
  titleCareer: 'Career',
  titleLetsstartsomethinggreat: 'Let\'s start something great',
  cookies: 'Cookies',
  solutions: 'Our solution fields',
  itSystemModernization: 'IT System Modernization',
  headlessCmsIntegration: 'Headless CMS Integration'
})
