// @flow

import React from 'react'
import classNames from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../components/basic/Grid'
import Headroom from 'react-headroom'
// import LogoInline from './components/LogoInline' // TO DO, enable animated logo when header is not rendered always
import Logo from './components/Logo'
import Mainnav from './components/Mainnav'
import baseStyles from './Header.scss'

type Props = {
  ...StyleProps,
  customNav?: any,
  hasCustomNav?: boolean,
  withTeaser?: boolean,
  contactBtn?: any
}

const Header = ({ styles, withTeaser, customNav, hasCustomNav, contactBtn }: Props) => (
  <Headroom disableInlineStyles className={styles.root}>
    <header className={classNames(styles.header, withTeaser && styles['is-withTeaser'])}>
      <Container className={styles.container} fluid>
        {/* <LogoInline /> */}
        <Logo/>
        <Mainnav customNav={customNav} hasCustomNav={hasCustomNav} contactBtn={contactBtn} />
      </Container>
    </header>
  </Headroom>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Header)
