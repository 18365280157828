// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React, { useCallback } from 'react'
import Button from '../../components/basic/Button'
import { Col, Container, Row } from '../../components/basic/Grid'
import logo from '../../images/logo/logo-freshcells-systems-engineering-inverted.svg'
import messages from './Footer.lang'
import baseStyles from './Footer.scss'

type Props = {
  modern?: boolean,
  ...StyleProps
}

const Footer = ({ modern, styles }: Props) => {
  const { formatMessage } = useIntl()

  const onCookieLinkClick = useCallback(e => {
    e.preventDefault()
    if (window && window.cookiehub) {
      window.cookiehub.openSettings()
    }
  })
  return (
    <footer className={classNames(styles.root, modern && styles.isModern)}>
      <div className={styles.linksWrap}>
        <Container fluid>
          <Row>
            <Col sm="6" md="4" className={styles.col}>
              <h3 className={styles.title}>
                <Link to="/solutions" className={styles.link}>
                  {formatMessage(messages.titleSolutions)}
                </Link>
              </h3>
              <ul className={styles.linkList}>
                <li>
                  <Link to="/solutions" className={styles.link}>
                    {formatMessage(messages.solutions)}
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/it-system-modernization/" className={styles.link}>
                    {formatMessage(messages.itSystemModernization)}
                  </Link>
                </li>
                <li>
                  <br />
                  <Link to="/products/" className={classNames(styles.link, styles.title)}>
                    {formatMessage(messages.titleProducts)}
                  </Link>
                </li>
                <li>
                  <Link to="/products/travelsandbox/" className={styles.link}>
                    {formatMessage(messages.Travelsandbox)}
                  </Link>
                </li>
                <li>
                  <Link to="/products/quickstart/" className={styles.link}>
                    {formatMessage(messages.Quickstart)}
                  </Link>
                </li>
                <li>
                  <Link to="/products/freshms/" className={styles.link}>
                    {formatMessage(messages.FreshMS)}
                  </Link>
                </li>
                <li>
                  <Link to="/products/fusion/" className={styles.link}>
                    {formatMessage(messages.Fusion)}
                  </Link>
                </li>
                <li>
                  <Link to="/products/mightyc/" className={styles.link}>
                    {formatMessage(messages.MightyC)}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col sm="6" md="4" className={styles.col}>
              <h3 className={styles.title}>
                <Link to="/services/" className={styles.link}>
                  {formatMessage(messages.titleServices)}
                </Link>
              </h3>
              <ul className={styles.linkList}>
                <li>
                  <Link to="/services/it-consulting/" className={styles.link}>
                    {formatMessage(messages.ITConsulting)}
                  </Link>
                </li>
                <li>
                  <Link to="/services/concept-strategy/" className={styles.link}>
                    {formatMessage(messages.ConceptAndStrategy)}
                  </Link>
                </li>
                <li>
                  <Link to="/services/ui-ux-design/" className={styles.link}>
                    {formatMessage(messages.UiUxDesign)}
                  </Link>
                </li>
                <li>
                  <Link to="/services/development/" className={styles.link}>
                    {formatMessage(messages.Development)}
                  </Link>
                </li>
                <li>
                  <Link to="/services/system-integration/" className={styles.link}>
                    {formatMessage(messages.SystemIntegration)}
                  </Link>
                </li>
                <li>
                  <Link to="/services/system-engineering/" className={styles.link}>
                    {formatMessage(messages.SystemEngineering)}
                  </Link>
                </li>
                <li>
                  <Link to="/services/app-development/" className={styles.link}>
                    {formatMessage(messages.AppDevelopment)}
                  </Link>
                </li>
                <li>
                  <Link to="/services/project-management/" className={styles.link}>
                    {formatMessage(messages.ProjektManagement)}
                  </Link>
                </li>
                <li>
                  <Link to="/services/headless-cms-integration/" className={styles.link}>
                    {formatMessage(messages.headlessCmsIntegration)}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col sm="6" md="4" className={styles.col}>
              <h3 className={styles.title}>
                <Link to="/references/" className={styles.link}>
                  {formatMessage(messages.titleReferences)}
                </Link>
              </h3>
              <ul className={styles.linkList}>
                <li>
                  <Link to="/case-study/" className={styles.link}>
                    {formatMessage(messages.caseStudies)}
                  </Link>
                </li>
                <li>
                  <Link to="/case-study/dertouristik/" className={styles.link}>
                    {formatMessage(messages.DERtouristik)}
                  </Link>
                </li>
                <li>
                  <Link to="/case-study/hlx-touristik/" className={styles.link}>
                    {formatMessage(messages.lufthansaHolidays)}
                  </Link>
                </li>
                <li>
                  <Link to="/case-study/plattenplaner/" className={styles.link}>
                    {formatMessage(messages.plattenplaner)}
                  </Link>
                </li>
                <li>
                  <Link to="/case-study/bedfinder/" className={styles.link}>
                    {formatMessage(messages.bedfinder)}
                  </Link>
                </li>
                <li>
                  <Link to="/case-study/etravel/" className={styles.link}>
                    {formatMessage(messages.etravel)}
                  </Link>
                </li>
                <li>
                  <Link to="/case-study/aldiana/" className={styles.link}>
                    {formatMessage(messages.aldiana)}
                  </Link>
                </li>
                <li>
                  <Link to="/case-study/weekendcom/" className={styles.link}>
                    {formatMessage(messages.weekend)}
                  </Link>
                </li>
                <li>
                  <Link to="/references/" className={styles.link}>
                    {formatMessage(messages.references)}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col sm="6" md="4" className={styles.col}>
              <h3 className={styles.title}>
                <Link to="/about-us/" className={styles.link}>
                  {formatMessage(messages.titleAboutUs)}
                </Link>
              </h3>
              <ul className={styles.linkList}>
                <li>
                  <Link to="/partner/" className={styles.link}>
                    {formatMessage(messages.Partner)}
                  </Link>
                </li>
                <li>
                  <Link to="/about-us/why-agile/" className={styles.link}>
                    {formatMessage(messages.WhyAgile)}
                  </Link>
                </li>
                <li>
                  <Link to="/about-us/technologies/" className={styles.link}>
                    {formatMessage(messages.Technologies)}
                  </Link>
                </li>
                <li>
                  <Link to="/newsroom/" className={styles.link}>
                    {formatMessage(messages.newsroom)}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col sm="6" md="4" className={styles.col}>
              <h3 className={styles.title}>
                <Link to="/career/" className={styles.link}>
                  {formatMessage(messages.titleCareer)}
                </Link>
              </h3>
              <ul className={styles.linkList}>
                <li>
                  <Link to="/career/" className={styles.link}>
                    {formatMessage(messages.WorkingAtFreshcells)}
                  </Link>
                </li>
                <li>
                  <Link to="/career/" className={styles.link}>
                    {formatMessage(messages.Jobs)}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col sm="6" md="4" className={styles.col}>
              <h3 className={styles.title}>
                <Link to="/contact/" className={styles.link}>
                  {formatMessage(messages.titleLetsstartsomethinggreat)}
                </Link>
              </h3>
              <ul className={styles.linkList}>
                <li>
                  <a
                    href="tel: +49 (0) 211 933 01 10"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classNames(styles.link, styles.linkIcon)}
                  >
                    <span className={classNames(styles.icon, 'icon-phone')} />
                    +49 (0) 211 933 01 10
                  </a>
                </li>
                <li>
                  <a
                    href="mailto: start@freshcells.de"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classNames(styles.link, styles.linkIcon)}
                  >
                    <span className={classNames(styles.icon, 'icon-email')} />
                    start@freshcells.de
                  </a>
                </li>
              </ul>
              <Link to="/contact/">
                <Button styles={styles.btn} tag="span" block color="accent" iconRight="icon-arrow_03-right-medium">
                  {formatMessage(messages.GetInTouch)}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.infoWrap}>
        <Container className={styles.infoContainer} fluid>
          <div className={styles.copyrightWrap}>
            <img width="226" height="31" src={logo} className={styles.logo} alt="freshcells Logo" />
            <div className={styles.copyright}>
              {formatMessage(messages.Copyright, {
                currentYear: new Date().getFullYear()
              })}
            </div>
          </div>
          <div className={styles.contactsWrap}>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <a
                  href="https://www.youtube.com/@freshcellsgmbh8752"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactLink}
                  aria-label="Link to youtube channel"
                >
                  <span className={classNames(styles.contactIcon, 'icon-youtube', styles.iconYoutube)} />
                </a>
              </li>
              <li className={styles.listItem}>
                <a
                  href="https://www.facebook.com/freshcells"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactLink}
                  aria-label="Link to facebook page"
                >
                  <span className={classNames(styles.contactIcon, 'icon-facebook')} />
                </a>
              </li>
              <li className={styles.listItem}>
                <a
                  href="https://www.instagram.com/freshcells_systems_engineering"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactLink}
                  aria-label="Link to instagram page"
                >
                  <span className={classNames(styles.contactIcon, 'icon-instagram')} />
                </a>
              </li>
              <li className={styles.listItem}>
                <a
                  href="https://www.linkedin.com/company/freshcells-systems-engineering-gmbh/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactLink}
                  aria-label="Link to linkedIn page"
                >
                  <span className={classNames(styles.contactIcon, 'icon-linkedin')} />
                </a>
              </li>
              <li className={styles.listItem}>
                <a
                  href="http://www.kununu.com/de/nrw/duesseldorf/in/freshcells-systems-engineering1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactLink}
                  aria-label="Link to kununu page"
                >
                  <span className={classNames(styles.contactIcon, 'icon-kununu')} />
                </a>
              </li>
              <li className={styles.listItem}>
                <a
                  href="https://www.xing.com/companies/freshcellssystemsengineeringgmbh"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactLink}
                  aria-label="Link to xing page"
                >
                  <span className={classNames(styles.contactIcon, 'icon-xing')} />
                </a>
              </li>
            </ul>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <button type="button" className={styles.link} onClick={onCookieLinkClick}>
                  {formatMessage(messages.cookies)}
                </button>
              </li>
              <li className={styles.listItem}>
                <Link to="/privacy-policy/" className={styles.link}>
                  {formatMessage(messages.PrivacyPolicy)}
                </Link>
              </li>
              <li className={styles.listItem}>
                <Link to="/imprint/" className={styles.link}>
                  {formatMessage(messages.Imprint)}
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Footer)
