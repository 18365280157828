// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { IntlContextConsumer, changeLocale, useIntl } from 'gatsby-plugin-intl'
import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../../../../components/basic/Dropdown'
import de from '../../../../images/flags/de.svg'
import en from '../../../../images/flags/gb.svg'
import messages from './Language.lang'
import baseStyles from './Language.scss'

type Props = {
  ...StyleProps
}

const Language = ({ styles }: Props) => {
  const { formatMessage } = useIntl()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => {
    setDropdownOpen(prev => !prev)
  }

  const languageName = {
    en: formatMessage(messages.english),
    de: formatMessage(messages.german)
  }

  return (
    <Dropdown styles={styles.root} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle nav styles={styles.toggle}>
        <IntlContextConsumer>
          {({ language: currentLocale }) => (
            <>
              <img className={styles.flag} src={currentLocale === 'en' ? en : de} alt={currentLocale} />
              <span className={styles.languageToggleLabel}>{languageName[currentLocale]}</span>
            </>
          )}
        </IntlContextConsumer>
      </DropdownToggle>
      <DropdownMenu styles={styles.dropdownMenu} direction={'right'} end={true}>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map(language => (
              <DropdownItem
                key={language}
                onClick={() => {
                  changeLocale(language)

                  const cookiehubElements = document.getElementsByClassName('ch2')
                  for (let i = 0; i < cookiehubElements.length; ++i) {
                    // $FlowFixMe
                    document.body.removeChild(cookiehubElements[i])
                  }
                  if (window.cookiehub) {
                    window.cookiehub.Popup.prototype.choice.categories = []

                    window.cookiehub.load({
                      onInitialise: function(status) {
                        if (this.hasConsented('analytics')) {
                          // $FlowFixMe
                          loadGA() // eslint-disable-line no-undef
                        }
                      },
                      onAllow: function(category) {
                        if (category === 'analytics') {
                          // $FlowFixMe
                          loadGA() // eslint-disable-line no-undef
                        }
                      },
                      language: language
                    })
                  }
                }}
                styles={styles.dropdownItem}
                active={currentLocale === language}
              >
                <img className={styles.flag} src={language === 'en' ? en : de} alt={language} />
                <div className={styles.language}>{languageName[language]}</div>
              </DropdownItem>
            ))
          }
        </IntlContextConsumer>
      </DropdownMenu>
    </Dropdown>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Language)
