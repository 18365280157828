// @flow
import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Collapsible from '../../../../components/basic/Collapsible'
import classNames from 'classnames'
import baseStyles from './Subnav.scss'

type Props = {
  ...StyleProps,
  parentLink: Object,
  children: Object
}

const Subnav = ({ styles, parentLink, children }: Props) => (
  <div className={classNames(styles.root, { styles })}>
    <Collapsible
      className={styles.collapsible}
      isInitiallyOpen={false}
      trigger={parentLink}
    >
      {children}
    </Collapsible>
    <div className={styles.parentLinkDesktop}>{parentLink}</div>
    <div className={styles.desktopContainer}>
      {children}
    </div>
  </div>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Subnav)
