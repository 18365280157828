// @flow
import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Link } from 'gatsby-plugin-intl'
import LogoSrc from '../../../../images/logo/logo-freshcells-systems-engineering.svg'
import baseStyles from './Logo.scss'

type Props = {
  ...StyleProps
}

const Logo = ({ styles }: Props) => (
  <Link to='/' className={styles.root}>
    <img width="260" height="37" src={LogoSrc} className={styles.img} alt="freshcells Logo" />
  </Link>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Logo)
